import React, {useState, useEffect} from 'react';
import {Grid, FormControl, RadioGroup, Radio, FormControlLabel, CircularProgress, ListItemText, List, ListItem, TextField, Button,
  Dialog, DialogActions, DialogContent , DialogContentText,DialogTitle} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import api from './utils/api';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  button: {
    float: 'right',
    marginRight: 5
  }
}));


function ListCustomer(props) {

  return (
    <List
    component="nav"
    aria-labelledby="nested-list-subheader"
  >
    {props.customerList.map((item, index) => {
      return (<ListItem button key={index}> <ListItemText primary={item.name} secondary={item.email} onClick={() => props.onClick(item)} key={item.entity_id}/></ListItem>)
    })}
     </List>
  )
}

export default function CustomerForm(props) {

  const classes = useStyles();
  const [customer, setCustomer] = useState(props.customer);
  // const [customerList, setCustomerList] = useState([]);
  // const [search, setSearch] = useState('');
  // const [searching, setSearching] = useState(false);
  const [error, setError] = React.useState({});
  const [openDialog , setOpenDialog] = React.useState(false);

  // useEffect(() => {
  //   searchCustomer();
  // }, [search])

  
  useEffect(() => {
    if(error.message){
      setOpenDialog(true);
    }
  }, [error]);

  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // const searchCustomer = () => {

  //   if(search.length > 2){

  //       setSearching(true);
  //       api.get("clients", {search: search},
  //       success => {
  //         console.log("success", success);
  //         setCustomerList(success.customers);
  //         setSearching(false)
  //       },
  //       err =>{
  //         console.error(err)
  //         setSearching(false)

  //         if(err.message){
  //           setError(err);
  //         }
          
  //       } 
  //     );
  //   }else{
  //     setCustomerList([]);
  //     setSearching(false)
  //   }
  // }

  const isValid = () => {
    let isValid = customer.firstname && customer.lastname && customer.email && customer.billing_telephone;

    if(isValid && customer.customer_type == '2'){
      isValid = customer.billing_postcode && (!customer.billing_company || customer.street1);
    }
      return isValid;
  }

  // const pickCustomer = (item) => {

  //   if(item.billing_street){
  //     let street = item.billing_street.split('\n');
  //     item.street1 = street[0];
  //     if(street[1]){
  //       item.street2 = street[1];
  //     }
  //   }

  //   setCustomer(item);
  //   // setSearch(item.name);
  //   setCustomerList([]);

  //   //props.onSelectCustomer(item);
  // }

  const changeDataCustomer = (field, value) => {
    console.log('changeDataCustomer '+field, value);

    let nCustomer = {...customer};
    nCustomer[field] = value;
    setCustomer(nCustomer);
  }
  const reinitCustomer = () => {
    setCustomer({
      firstname: '',
      lastname: '',
      email: '',
      billing_telephone: '',
      address1: '',
      address2: '',
      billing_postcode: '',
      billing_city: '',
      billing_country_id: 'FR',
      street1: '',
      street2: '',
      billing_company: '',
      customer_type : '1'
    });
  }

  return (
    
    <React.Fragment>
      

      <Dialog 
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogError}>Erreur</DialogTitle>
        <DialogContent className={classes.dialogError}>
          <DialogContentText id="alert-dialog-description" style={{color: "#611a59"}}>
            {error.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogError}>
          <Button onClick={handleCloseDialog} color="primary"style={{color: "#d0152e"}}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      
       {/* <Grid container spacing={3}>
       <Grid item xs={12} style={{position: 'relative', marginBottom: 20}}>
        <TextField
            id="search"
            name="search"
            label="Rechercher un client"
            value={customer.entity_id ? customer.name : search}
            fullWidth
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              readOnly: customer.entity_id != null,
            }}
          />
        <CloseIcon style={{position: 'absolute', right: 10, top: 30, display: customer.entity_id && !searching ? 'inline' : 'none'}} 
            onClick={() => {
                reinitCustomer();
                setSearch('');
            }}/>
          {searching && (<CircularProgress style={{position: 'absolute', right: 10, top: 30, width : 20, height: 20}}  />)}

        <CloseIcon style={{position: 'absolute', right: 10, top: 30, display: customer.entity_id ? 'inline' : 'none'}} 
            onClick={() => {
              reinitCustomer();
              setSearch('');
            }}/>
        {customerList.length > 0 && (<Grid item xs={12} style={{margin: '0 5px', position:'absolute', right: 5, left: 5, background: '#FFF', zIndex: 9999, width: '100%', border:'1px solid #DDD'}}>
          <ListCustomer customerList={customerList} onClick={(item) => {
            pickCustomer(item);
          }}
          /> 
        </Grid>)}
      </Grid>
      </Grid> */}
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <RadioGroup row aria-label="customer_type" name="customer_type" defaultValue="1" value={customer.customer_type || ""} onChange={(e) => changeDataCustomer('customer_type', e.target.value)}>
              <FormControlLabel value="1" control={<Radio />} label="Particulier" />
              <FormControlLabel value="2" control={<Radio />} label="Professionnel" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstname"
            name="firstname"
            label="Prénom"
            fullWidth
            value={customer.firstname}
            InputLabelProps={{ shrink: customer.firstname != '' && true }}  
            onChange={(e) => changeDataCustomer('firstname', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastname"
            name="lastname"
            value={customer.lastname}
            label="Nom"
            fullWidth
            autoComplete="last-name"
            InputLabelProps={{ shrink: customer.lastname != '' && true }}  
            onChange={(e) => changeDataCustomer('lastname', e.target.value)}
          />
        </Grid>
        {customer.customer_type == '2' && (<Grid item xs={12}>
          <TextField
            id="company"
            name="billing_company"
            label="Société"
            fullWidth
            value={customer.billing_company}
            InputLabelProps={{ shrink: customer.billing_company != '' && true }}  
            onChange={(e) => changeDataCustomer('billing_company', e.target.value)}
          />
        </Grid>)}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="email"
            name="email"
            value={customer.email}
            label="Email"
            fullWidth
            InputLabelProps={{ shrink: customer.email != '' && true }}  
            onChange={(e) => changeDataCustomer('email', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="telephone"
            name="billing_telephone"
            value={customer.billing_telephone}
            label="Téléphone"
            fullWidth
            InputLabelProps={{ shrink: customer.billing_telephone != '' && true }}  
            onChange={(e) => changeDataCustomer('billing_telephone', e.target.value)}
          />
        </Grid>
        {customer.customer_type == '2' && (
          <React.Fragment>
        <Grid item xs={12}>
          <TextField         
            required={customer.customer_type == '2'}
            id="street1"
            name="street1"
            label="Adresse 1"
            fullWidth
            autoComplete="street-line1"
            value={customer.street1}
            InputLabelProps={{ shrink: customer.street1 != '' && true }}  
            onChange={(e) => changeDataCustomer('street1', e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="street2"
            name="street2"
            label="Adresse 2"
            fullWidth
            autoComplete="street-line2"
            value={customer.street2}
            InputLabelProps={{ shrink: customer.street2 != '' && true }}  
            onChange={(e) => changeDataCustomer('street2', e.target.value)}
          />
        </Grid>
        </React.Fragment>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            required={customer.customer_type == '2'}
            id="city"
            name="billing_city"
            label="Ville"
            value={customer.billing_city}
            InputLabelProps={{ shrink: customer.billing_city != '' && true }}  
            onChange={(e) => changeDataCustomer('billing_city', e.target.value)}
            fullWidth
            autoComplete="city"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required={customer.customer_type == '2'}
            id="zip"
            name="billing_postcode"
            label="Code postal"
            fullWidth
            autoComplete="zip-code"
            value={customer.billing_postcode}
            InputLabelProps={{ shrink: customer.billing_postcode != '' && true }}  
            onChange={(e) => changeDataCustomer('billing_postcode', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required={customer.customer_type == '2'}
            id="country"
            name="billing_country_id"
            label="Pays"
            fullWidth
            autoComplete="country"
            value={customer.billing_country_id}
            InputLabelProps={{ shrink: customer.billing_country_id != '' && true }}  
            onChange={(e) => changeDataCustomer('billing_country_id', e.target.value)}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
            label="Use this address for payment details"
          />
        </Grid> */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={!isValid()}
            onClick={() => props.onSelectCustomer(customer)}
            className={classes.button}
          >
            Valider
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => props.handleBack()}
            className={classes.button}
          >
            Annuler
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
