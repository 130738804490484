import React from 'react';
import {Modal, List, ListItem, ListItemIcon, ListItemText, IconButton, Grid} from '@material-ui/core';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: 600,
      margin: "70px auto",
    },
    selected: {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    }
  }));

export default function LastReservationsModal(props) {

    const classes = useStyles();

    const getStartEndStr = (elt) => {
      let str = '';

      str = elt.start.address+"\n"+elt.end.address;

      return str;

    }
    return (
        <Modal
          open={props.open}
          onClose={props.onClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
        <div className={classes.paper}>
          <List component="nav" aria-label="main cars">
            {props.list.map((elt, index) => 
                <ListItem key={elt.itemId}>
                  {/* <ListItemIcon>
                    <DriveEta />
                  </ListItemIcon> */}
                  <Grid container direction="column">
                    <ListItemText primary={elt.start.address} />
                    <ListItemText primary={elt.end.address} secondary={`${moment(elt.date).format('DD/MM/YYYY - HH:mm')}`}/>
                  </Grid>
                  <ListItemIcon>
                    <IconButton color="default" aria-label="Créer à partir de cette réservation" component="span" onClick={() => props.onClick(elt)}>
                      <PostAddIcon />
                    </IconButton>
                  </ListItemIcon>
              </ListItem>
            )}
            </List>
          </div>
            </Modal>
)

}