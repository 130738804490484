import React from "react";
import {
	Modal,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Radio,
} from "@material-ui/core";
import { DriveEta } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	selected: {
		backgroundColor: "rgba(0, 0, 0, 0.04)",
	},
}));

export default function CarModal(props) {
	const classes = useStyles();
	return (
		<List component="nav" aria-label="main cars">
			{props.carList
				.filter(
					(elt) =>
						elt.nbMaxPers >= props.nbPersons &&
						props.soute < elt.nbLuggage &&
						(elt.carTypeName != "Berline" || props.soute + props.cabine < 5)
				)
				.map((elt, index) => (
					<ListItem
						className={
							props.selectedCar && elt.carTypeId == props.selectedCar.carTypeId
								? classes.selected
								: null
						}
						key={elt.carTypeId}
						button
						onClick={() => props.handleSelectCar(elt.carTypeId)}
					>
						<ListItemIcon>
							<DriveEta />
						</ListItemIcon>
						<ListItemText
							primary={`${elt.carTypeName} - ${elt.amount} €`}
							secondary={`Max pers. ${elt.nbMaxPers} - Max bag. ${elt.nbLuggage}`}
						/>
						<ListItemIcon>
							<Radio
								edge="start"
								checked={
									props.selectedCar &&
									elt.carTypeId == props.selectedCar.carTypeId
								}
								tabIndex={-1}
								disableRipple
								inputProps={{ "aria-labelledby": "car-" + elt.carTypeId }}
							/>
						</ListItemIcon>
					</ListItem>
				))}
		</List>
	);
}
