import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import { DriveEta, Person } from '@material-ui/icons';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '15px 0'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 12,
  },
  pos: {
    marginBottom: 12,
    fontSize: 15,
  },
  icon: {
    paddingRight: 5
  },
  buttonContainer: {
    textAlign: 'center'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  address: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 5,
  },
  gridContainer : {
    marginTop: 5,
    marginBottom: 5
  }
}));

export default function ReservationsList(props) {
  const classes = useStyles();
  // const bull = <span className={classes.bullet}>•</span>;
  console.log(props.reservationsList);
  return (
      <React.Fragment>
      <Grid item xs={12} className={classes.root}>
      {props.reservationsList.map((elt, index) => 
        elt.validated === true && (<Grid container key={index} className={classes.gridContainer}>
            <Card variant="outlined">
            <CardContent>
              <Grid container>
                  <Grid item xs={12}>
                    <Typography className={classes.address}>
                      {elt.start.address}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.address}>
                      {elt.end.address}
                    </Typography>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item xs={3}>
                    <Typography className={classes.pos} color="textSecondary">
                      {moment(elt.date).format('DD/MM/YYYY')} à {moment(elt.hour).format('HH:mm')}
                    </Typography>
                    </Grid>
                    <Grid item xs={3}>
                    {/* <Typography className={classes.icon} color="textSecondary"> */}
                      <DriveEta fontSize="small" />
                    {/* </Typography> */}
                    <Typography component="body1"  className={classes.pos} color="textSecondary">
                      {elt.car.carTypeName}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {/* <Typography color="textSecondary" className={classes.icon}> */}
                      <Person fontSize="small" />
                    {/* </Typography> */}
                    <Typography component="body1" className={classes.pos} color="textSecondary">
                      {elt.nbPersons}
                    </Typography>
                  </Grid>
                  </Grid>
                  {/* <Grid container className={classes.gridContainer}>
                  
                  </Grid> */}
                  {elt.bookingType !== 1 && (
                  <Grid container className={classes.gridContainer}>
                  <Grid item xs={12}>
                    <Typography component="body1"  className={classes.pos} color="textSecondary">
                      N° vol : {elt.flightNum}
                    </Typography>
                    
                    <Typography component="body1"  className={classes.pos} color="textSecondary">
                    {" / "} {elt.bookingType === 2 ? 'Décollage à' : 'Atterrissage à'} {moment(elt.flightTime).format('HH:mm')}
                    </Typography>
                    
                    <Typography component="body1"  className={classes.pos} color="textSecondary">
                    {" / "} Terminal {elt.terminal}
                    </Typography>
                    
                    <Typography component="body1"  className={classes.pos} color="textSecondary">
                      {" / "} {elt.bookingType === 2 ? 'Destination : ' : 'Provenance : '} {elt.origin}
                    </Typography>
                  </Grid>
                  </Grid>)}
                  
                  {elt.meetingPoint !== '' && (
                  <Grid container className={classes.gridContainer}>
                  <Grid item xs={12}>
                    <Typography component="body1"  className={classes.pos} color="textSecondary">
                      Point de rendez-vous : {elt.meetingPoint}
                    </Typography>
                  </Grid>
                  </Grid>)}
                  {elt.autoroute == 1 && (
                  <Grid container className={classes.gridContainer}>
                    <Typography variant="body" className={classes.pos} color="textSecondary">
                      Avec Autoroute
                    </Typography>
                  </Grid>)}
                  <Grid container className={classes.gridContainer}>
                    <Typography className={classes.address}>
                      Prix:  {elt.car.amount} €
                    </Typography>
                  </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Grid item xs={12} className={classes.buttonContainer}>
                  <Button variant="outlined" size="small" onClick={() => props.editReservation(index)}>Modifier</Button>
                  <Button style={{marginLeft: 5}} variant="outlined" color="secondary" size="small" onClick={() => props.cancelReservation(index)}>Supprimer</Button>
                </Grid>
            </CardActions>
            </Card>
        </Grid>)
    )}
    </Grid>
    <Grid item xs={12} className={classes.buttonContainer}>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={() => props.onClick()}
      >
        Ajouter une réservation
      </Button>
    </Grid>
    </React.Fragment>
  );
}