import moment from "moment";
import { apiUrl } from "./constants";
import hmacSHA256 from "crypto-js/hmac-sha256";

import axios from "axios";

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
	},
});

const generateAuthorization = (token, timestamp) => {
	return hmacSHA256(
		token + timestamp,
		"da39a3ee5e6b4b0d3255bfef95601890afd80709"
	);
};

instance.interceptors.request.use(
	(config) => {
		let timestamp = moment().format("X");
		let token = JSON.parse(window.localStorage.getItem("token")) || null;

		config.headers = {
			"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
			Authorization: "Bearer " + generateAuthorization(token, timestamp),
			timestamp: moment().format("X"),
			token: JSON.parse(window.localStorage.getItem("token")),
		};

		//   config.headers['Authorization'] =
		// 	Store.getState().appStore.restaurant.token;
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

// const buildFormData = (formData, data, parentKey) => {
// 	if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
// 	  Object.keys(data).forEach(key => {
// 		buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
// 	  });

// 	} else {
// 	  const value = data == null ? '' : data;

// 	  formData.append(parentKey, value);
// 	}
//   }

// const jsonToFormData = (data) => {
// 	const formData = new FormData();

// 	buildFormData(formData, data);

// 	return formData;
// }

const get = (uri, data, successCallback, failureCallback) => {
	// let headers = {
	// 	"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
	// 	Authorization: "Bearer " + generateAuthorization(token, timestamp),
	// 	timestamp: moment().format("X"),
	// 	token: JSON.parse(window.localStorage.getItem("token")),
	// };
	// console.log(headers);

	let token = JSON.parse(window.localStorage.getItem("token")) || null;

	return instance
		.get(uri + "?" + new URLSearchParams({ ...data, token }), {})
		.then((response) => {
			if (response.status === 401) {
				console.log("reponse 401");
				return failureCallback({ message: "Non autorisé" });
			} else {
				// return response.json().then((json) => {
				return successCallback(response.data);
				// });
			}
		})
		.catch((err) => {
			console.log(err);

			// if(err.status == 401){
			// 	err.message= 'Non autorisé';
			// }
			return failureCallback(err);
		});
};

const post = (uri, data, successCallback, failureCallback) => {
	var formData = new FormData();
	// var formData = jsonToFormData(data);
	// for ( var key in data ) {
	// 	console.log(key,data[key]);
	// 	formData.append(key, data[key]);
	// }

	// console.log('formData',data);
	// console.log('formData', formData.get('start[address]'));
	// formData.append('request', 'ici');
	formData.append("request", JSON.stringify(data));

	// let timestamp = moment().format("X");
	let token = JSON.parse(window.localStorage.getItem("token")) || null;

	// let headers = {
	// 	"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
	// "Authorization": 'Bearer ' + generateAuthorization(token, timestamp),
	// "timestamp": moment().format('X'), "token": JSON.parse(window.localStorage.getItem("token"))+''
	// };
	// console.log(headers);

	return instance
		.post(uri + "?" + new URLSearchParams({ token }), formData)
		.then((response) => {
			if (response.status == 401) {
				return failureCallback(response);
			} else if (response.status >= 400) {
				if (failureCallback) return failureCallback(response);
			} else {
				return successCallback(response.data);
			}
		})
		.catch((err) => {
			console.log(uri);
			console.log(err);
			if (failureCallback) return failureCallback(err);
		});
};

export default { get, post };
