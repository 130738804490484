import React, {useState, useEffect} from 'react';
import {Grid, FormControl, RadioGroup, Radio, FormControlLabel, CircularProgress, ListItemText, List, ListItem, TextField, Button,
  Dialog, DialogActions, DialogContent , DialogContentText,DialogTitle} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import api from '../utils/api';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    dialogError: {
      backgroundColor: "#eacbc8",
      color: "#d0152e"
    }
  }));

export default function DialogError(props) {
  const [error, setError] = React.useState(props.error || {});
  const [openDialog , setOpenDialog] = React.useState(props.openDialog || false);
  

  const classes = useStyles();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
    
  React.useEffect(() => {
    console.log('useEffect DialogError')
    if(error.message){
      setOpenDialog(true);
    }
  }, [error]);

  return (
    <React.Fragment>
      <Dialog 
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogError}>Erreur</DialogTitle>
        <DialogContent className={classes.dialogError}>
          <DialogContentText id="alert-dialog-description" style={{color: "#611a59"}}>
            {error.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogError}>
          <Button onClick={handleCloseDialog} color="primary"style={{color: "#d0152e"}}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
  </React.Fragment>
)

}