import React from 'react';
// import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
// import ProTip from './ProTip';
import Checkout from './Checkout';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: "2px solid #2abdab", 
    textAlign: "center",
    height: 60,
    padding: "5px 0"
  },
  img: {
    height: "100%",
  }
}));

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://material-ui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

export default function App() {
  const classes = useStyles();

  return (
      <Box>
        <div className={classes.header}>
        {/* <Typography variant="h4" component="h1" gutterBottom align="center">
          Nouvelle réservation
        </Typography> */}
          <img className={classes.img} alt="complex" src="https://www.pickmecab.fr/assets/default/img/logo_brand_gris.png" />
        </div>
        <MuiPickersUtilsProvider utils={MomentUtils}>
        <Checkout />
        </MuiPickersUtilsProvider>
      </Box>
  );
}
