import React, { useState } from "react";
import {
	Grid,
	Typography,
	TextField,
	Button,
	InputAdornment,
	SvgIcon,
	InputLabel,
	MenuItem,
	ListItem,
	ListItemIcon,
	ListItemText,
	FormControl,
	Select,
	FormControlLabel,
	Checkbox,
	ListItemSecondaryAction,
	IconButton,
	List,
	Backdrop,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { DatePicker, TimePicker } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
	LocationOn,
	DriveEta,
	Person,
	Edit,
	ImportExport,
	Add,
	Delete,
	History,
	LocalAirport,
	Train,
} from "@material-ui/icons";

import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

import api from "./utils/api";
import CarList from "./components/CarModal";
// import MomentUtils from '@date-io/moment';
import moment from "moment-timezone";

// function loadScript(src, position, id) {
//   if (!position) {
//     return;
//   }

//   const script = document.createElement('script');
//   script.setAttribute('async', '');
//   script.setAttribute('id', id);
//   script.src = src;
//   position.appendChild(script);
// }

const autocompleteService = { current: null };
// const geocoderService = { current: null };

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
	margin: {
		margin: theme.spacing(1),
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	button: {
		float: "right",
		marginRight: 5,
	},
	error: {
		color: "red",
	},
	dialogError: {
		backgroundColor: "#eacbc8",
		color: "#d0152e",
		width: 500,
	},
}));

export default function ReservationForm(props) {
	const classes = useStyles();
	const customer = props.customer;

	const mostUsesAddresses =
		JSON.parse(localStorage.getItem("mostUsesAddresses")) || [];

	const [startValue, setStartValue] = useState(
		props.reservation.start ? props.reservation.start : ""
	);
	const [startInputValue, setStartInputValue] = useState(
		props.reservation.start ? props.reservation.start.address : ""
	);
	const [startOptions, setStartOptions] = useState(props.historyAddresses);

	const [endValue, setEndValue] = useState(
		props.reservation.end ? props.reservation.end : ""
	);
	const [endInputValue, setEndInputValue] = useState(
		props.reservation.end ? props.reservation.end.address : ""
	);
	const [endOptions, setEndOptions] = useState(props.historyAddresses);

	const [loading, setLoading] = React.useState(false);

	const [reservationData, setReservationData] = useState(props.reservation);

	const [resultCheckAddress, setResultCheckAddress] = useState({});
	const [flightInfo, setFlightInfo] = useState({});
	const [carModalOpen, setCarModalOpen] = useState(false);
	const [carModalList, setCarModalList] = useState([]);
	const [terminals, setTerminals] = useState([]);
	const [formErrors, setFormErrors] = useState({});
	const [error, setError] = React.useState({});
	const [openDialog, setOpenDialog] = React.useState(false);

	const loaded = React.useRef(false);
	const init = React.useRef(true);
	const callCheck = React.useRef(false);
	const geocoderService = React.useRef(null);

	const handleDateChange = (date) => {
		callCheck.current = true;
		setReservationData((prevReservationData) => ({
			...prevReservationData,
			date: date,
		}));
	};

	const handleHeureChange = (date, noReload) => {
		if (!noReload) {
			callCheck.current = true;
		}
		setReservationData((prevReservationData) => ({
			...prevReservationData,
			hour: moment(date).tz("Europe/Paris"),
		}));
	};

	const handleFlightTimeChange = (date) => {
		callCheck.current = true;
		setReservationData((prevReservationData) => ({
			...prevReservationData,
			flightTime: date,
		}));
	};

	const handleChange = ({ target: { name, value, type, checked } }) => {
		const updatePath = name.split(".");

		// if the input is a checkbox then use callback function to update
		// the toggle state based on previous state
		if (type === "checkbox") {
			if (name == "autoroute" && (!customer || customer.with_autoroute != 1)) {
				callCheck.current = true;
			}
			setReservationData((prevReservationData) => ({
				...prevReservationData,
				[name]: checked,
			}));
		} else if (updatePath.length === 1) {
			const [key] = updatePath;
			if (name === "soute" || name === "cabine") {
				callCheck.current = true;
			}

			setReservationData((prevReservationData) => ({
				...prevReservationData,
				[key]: value,
			}));
		}
	};

	const addIntermediatePoint = () => {
		let intermediatePoints = reservationData.intermediatePoints || [];
		intermediatePoints.push({ value: "", options: [], inputValue: "" });

		setReservationData((prevReservationData) => ({
			...prevReservationData,
			intermediatePoints,
		}));
	};

	const removeIntermediatePoint = (index) => {
		callCheck.current = true;
		let intermediatePoints = reservationData.intermediatePoints || [];
		intermediatePoints.splice(index, 1);

		setReservationData((prevReservationData) => ({
			...prevReservationData,
			intermediatePoints,
		}));
	};

	const handleChangeTerminal = ({ terminal, meetingPoint }) => {
		setReservationData((prevReservationData) => ({
			...prevReservationData,
			terminal,
			meetingPoint,
		}));
	};

	const handleSelectCar = (carTypeId) => {
		let selectedCar = resultCheckAddress.go.priceList.find(
			(elt) => elt.carTypeId == carTypeId
		);
		let tempAddress = reservationData.tempAddress;

		if (
			resultCheckAddress.pmcAddress &&
			resultCheckAddress.bookingType != 1 &&
			startValue.address != resultCheckAddress.pmcAddress.address &&
			endValue.address != resultCheckAddress.pmcAddress.address
		) {
			tempAddress = { ...startValue };
			if (resultCheckAddress.bookingType == 3) {
				tempAddress = { ...endValue };
			}
		}

		let changes = {};
		if (selectedCar.carTypeId == 1111) {
			let pmcCoords = resultCheckAddress.pmcAddress.coords.split(",");
			let pmcAddress = {
				address: resultCheckAddress.pmcAddress.address,
				description: resultCheckAddress.pmcAddress.address,
				geometry: {
					location: { latitude: pmcCoords[0], longitude: pmcCoords[1] },
				},
			};
			if (resultCheckAddress.bookingType == 3) {
				changes = { end: { ...pmcAddress } };
				setEndValue({ ...pmcAddress });
				setEndInputValue(resultCheckAddress.pmcAddress.address);
			} else {
				changes = { start: { ...pmcAddress } };
				setStartValue({ ...pmcAddress });
				setStartInputValue(resultCheckAddress.pmcAddress.address);
			}
		} else if (tempAddress) {
			if (resultCheckAddress.bookingType == 3) {
				changes = { end: tempAddress };
				setEndValue({ ...tempAddress });
				setEndInputValue(tempAddress.address);
			} else {
				changes = { start: tempAddress };
				setStartValue({ ...tempAddress });
				setStartInputValue(tempAddress.address);
			}
		}

		setReservationData({
			...reservationData,
			...changes,
			car: selectedCar,
			tempAddress,
		});
	};

	const handleChangePassager = (event, index) => {
		reservationData.passagers[index][event.target.name] = event.target.value;

		//console.log(event.name, index);
		setReservationData((prevReservationData) => ({
			...prevReservationData,
			passagers: reservationData.passagers,
		}));
	};

	// if (typeof window !== 'undefined' && !loaded.current) {
	//   if (!document.querySelector('#google-maps')) {
	//     loadScript(
	//       'https://maps.googleapis.com/maps/api/js?key=AIzaSyC3-ozStKYQws5zncuv8mS8CaniJmlK4n4&libraries=places',
	//       document.querySelector('head'),
	//       'google-maps',
	//     );
	//   }

	//   loaded.current = true;
	// }

	const fetch = React.useMemo(
		() =>
			throttle((request, callback) => {
				autocompleteService.current.getPlacePredictions(request, callback);
			}, 200),
		[]
	);

	const checkHistoryAndMostUsesAddresses = (search) => {
		let regexp = new RegExp(search, "gi");
		return [...mostUsesAddresses, ...props.historyAddresses].filter((elt) =>
			elt.address.match(regexp)
		);
	};

	React.useEffect(() => {
		let active = true;

		if (!autocompleteService.current && window.google) {
			autocompleteService.current =
				new window.google.maps.places.AutocompleteService();
		}
		if (!autocompleteService.current) {
			return undefined;
		}

		if (startInputValue === "") {
			setStartOptions(
				startValue
					? [startValue]
					: [...mostUsesAddresses, ...props.historyAddresses]
			);
			return undefined;
		} else {
			let historyAndMostUsesAddresses =
				checkHistoryAndMostUsesAddresses(startInputValue);
			if (historyAndMostUsesAddresses.length) {
				setStartOptions(historyAndMostUsesAddresses);
				return undefined;
			}
		}

		fetch(
			{ input: startInputValue, componentRestrictions: { country: "fr" } },
			(results) => {
				if (active) {
					let newOptions = [];

					if (startValue) {
						newOptions = [startValue];
					}

					if (results) {
						newOptions = [...newOptions, ...results];
					}

					setStartOptions(newOptions);
				}
			}
		);

		return () => {
			active = false;
		};
	}, [startValue, startInputValue, fetch]);

	React.useEffect(() => {
		let active = true;

		if (!autocompleteService.current && window.google) {
			autocompleteService.current =
				new window.google.maps.places.AutocompleteService();
		}
		if (!autocompleteService.current) {
			return undefined;
		}

		if (endInputValue === "") {
			setEndOptions(
				endValue
					? [endValue]
					: [...mostUsesAddresses, ...props.historyAddresses]
			);
			return undefined;
		} else {
			let historyAndMostUsesAddresses =
				checkHistoryAndMostUsesAddresses(endInputValue);
			if (historyAndMostUsesAddresses.length) {
				setEndOptions(historyAndMostUsesAddresses);
				return undefined;
			}
		}

		fetch(
			{ input: endInputValue, componentRestrictions: { country: "fr" } },
			(results) => {
				if (active) {
					let newOptions = [];

					if (endValue) {
						newOptions = [endValue];
					}

					if (results) {
						newOptions = [...newOptions, ...results];
					}

					setEndOptions(newOptions);
				}
			}
		);

		return () => {
			active = false;
		};
	}, [endValue, endInputValue, fetch]);

	React.useEffect(() => {
		console.log("dans le useEffect des oints intermediaires");
		let active = true;

		if (callCheck.current) {
			checkAddress();

			callCheck.current = false;
			return;
		}
		if (!autocompleteService.current && window.google) {
			autocompleteService.current =
				new window.google.maps.places.AutocompleteService();
		}
		if (!autocompleteService.current) {
			return undefined;
		}
		let intermediatePoints = [...reservationData.intermediatePoints];
		let index = intermediatePoints.findIndex((elt) => elt.active === true);
		if (index === -1) {
			return;
		}
		let iPoint = { ...intermediatePoints[index] };
		if (iPoint.inputValue === "") {
			iPoint.options = iPoint.inputValue
				? [iPoint.inputValue]
				: [...mostUsesAddresses, ...props.historyAddresses];
			return undefined;
		} else {
			let historyAndMostUsesAddresses = checkHistoryAndMostUsesAddresses(
				iPoint.inputValue
			);
			if (historyAndMostUsesAddresses.length) {
				iPoint.options = historyAndMostUsesAddresses;
				return undefined;
			}
		}

		fetch(
			{ input: iPoint.inputValue, componentRestrictions: { country: "fr" } },
			(results) => {
				if (active) {
					let newOptions = [];

					if (iPoint.value) {
						newOptions = [iPoint.value];
					}

					if (results) {
						newOptions = [...newOptions, ...results];
					}

					iPoint.options = newOptions;
				}
				intermediatePoints[index] = iPoint;
				setReservationData({ ...reservationData, intermediatePoints });
			}
		);

		return () => {
			active = false;
		};
	}, [JSON.stringify(reservationData.intermediatePoints), fetch]);

	React.useEffect(() => {
		if (
			reservationData.start &&
			reservationData.end &&
			(callCheck.current || init.current)
		) {
			checkAddress();
		}
		callCheck.current = false;
		init.current = false;
	}, [reservationData.start, reservationData.end]);

	React.useEffect(() => {
		if (
			reservationData.date &&
			(reservationData.hour || reservationData.flightTime) &&
			callCheck.current
		) {
			checkAddress(true);
		}

		if (
			reservationData.car &&
			reservationData.nbPersons > reservationData.car.nbMaxPers
		) {
			removeCar();
			setCarModalOpen(true);
		}
		callCheck.current = false;
	}, [
		reservationData.date,
		reservationData.hour,
		reservationData.flightTime,
		reservationData.nbPersons,
		reservationData.autoroute,
		reservationData.soute,
		reservationData.cabine,
	]);

	React.useEffect(() => {
		props.validateReservation(reservationData);
	}, [reservationData]);

	React.useEffect(() => {
		if (error.message) {
			setOpenDialog(true);
		}
	}, [error]);

	const removeCar = () => {
		delete reservationData.car;
		setReservationData(reservationData);
	};
	const checkAddress = async (calculatePrice) => {
		let intermediatePoints = reservationData.intermediatePoints;
		if (startValue && endValue) {
			setLoading(true);
			let geocodeResultStart = startValue.geometry;
			let geocodeResultEnd = endValue.geometry;

			try {
				// setResultCheckAddress({});
				if (!geocoderService.current && window.google) {
					geocoderService.current = new window.google.maps.Geocoder();
				}

				if (!startValue.geometry || !startValue.geometry.location) {
					geocodeResultStart = await getGeocode(startValue.place_id);
					geocodeResultStart = {
						location: {
							latitude: geocodeResultStart.geometry.location.lat(),
							longitude: geocodeResultStart.geometry.location.lng(),
						},
					};
					setStartValue({ ...startValue, geometry: { ...geocodeResultStart } });
				}

				if (!endValue.geometry || !endValue.geometry.location) {
					geocodeResultEnd = await getGeocode(endValue.place_id);
					geocodeResultEnd = {
						location: {
							latitude: geocodeResultEnd.geometry.location.lat(),
							longitude: geocodeResultEnd.geometry.location.lng(),
						},
					};
					setEndValue({ ...endValue, geometry: { ...geocodeResultEnd } });
				}

				intermediatePoints.map(async (iPoint, index) => {
					// if(!iPoint.geometry || !iPoint.geometry.location){
					//   let iPointGeocodeResult = await getGeocode(iPoint.value.place_id);
					//   iPointGeocodeResult = {location: {latitude : iPointGeocodeResult.geometry.location.lat(), longitude: iPointGeocodeResult.geometry.location.lng()}}
					//   iPoint.geometry = {...geocodeResultEnd};
					// }
				});

				setReservationData({ ...reservationData, intermediatePoints });
			} catch (error) {
				setLoading(false);
				console.error(error);
				return;
			}
			let request = {
				start: {
					address: startValue.description,
					latitude: geocodeResultStart.location.latitude,
					longitude: geocodeResultStart.location.longitude,
				},
				end: {
					address: endValue.description,
					latitude: geocodeResultEnd.location.latitude,
					longitude: geocodeResultEnd.location.longitude,
				},
				// start: {address : startValue.description, latitude : 0, longitude : 0},
				// end: {address : endValue.description, latitude : 0, longitude : 0},
				// "start":{"address":"115 Rue Réaumur, Paris, France","latitude":48.86800100000001,"longitude":2.3425723},
				// "start":{"address":"Aéroport de Paris-Orly, Orly","latitude":48.7262356,"longitude":2.3630535},
				// "end":{"address":"20 rue Pierre Viala, 45000 Orléans, France","latitude":47.9177115,"longitude":1.9174879}
				// "start":{"address":"Rue de Gent, Hanovre, Allemagne","latitude":52.317593,"longitude":9.8180954},
				// "end":{"address":"Hotel Hanovre, Osterstraße, Hanovre, Allemagne","latitude":52.3711279,"longitude":9.7365157}
				// ];
			};

			intermediatePoints.map((iPoint, index) => {
				// request['ip'+(index+1)] = {address : iPoint.value.description, latitude : iPoint.geometry.location.latitude, longitude : iPoint.geometry.location.longitude};
				request["ip" + (index + 1)] = iPoint.value.description;
			});

			if (
				reservationData.date &&
				(reservationData.hour || reservationData.flightTime)
			) {
				request.date = moment(reservationData.date)
					.tz("Europe/Paris")
					.format("DD/MM/YYYY");

				if (reservationData.flightTime) {
					request.flightTime = moment(reservationData.flightTime)
						.tz("Europe/Paris")
						.format("HH:mm");
				} else {
					request.time = moment(reservationData.hour)
						.tz("Europe/Paris")
						.format("HH:mm");
				}
			}

			if (reservationData.autoroute) {
				request.autoroute = 1;
			}
			if (customer.entity_id) {
				request.customerId = customer.entity_id;
			}

			// request.nbPersons = reservationData.nbPersons;
			request.nbPersons = 1;
			if (reservationData && reservationData.isCollective) {
				request.nbPersons = reservationData.nbPersons;
			}

			request.luggage = (reservationData && reservationData.soute) || 0;
			request.luggage_cabine = (reservationData && reservationData.cabine) || 0;

			api.post(
				"order/calculate",
				request,
				(success) => {
					console.log("success", success);
					if (success.error) {
						setError(success.error);
					} else {
						setResultCheckAddress(success);

						let result = {
							town: success.store,
							bookingType: success.bookingType,
							isCollective: success.isCollective,
						};

						if (result.bookingType != 3) {
							result.meetingPoint = "";
						}

						if (success.go && success.go.priceList.length) {
							result.distance = success.go.distance;
							result.duration = success.go.duration;
							setCarModalList(success.go.priceList);
							if (
								!reservationData.car ||
								!success.go.priceList.find(
									(elt) => elt.carTypeId == reservationData.car.carTypeId
								)
							) {
								setCarModalOpen(true);
								setReservationData({ ...reservationData, car: {} });
							} else if (
								reservationData.car &&
								success.go.priceList.find(
									(elt) => elt.carTypeId == reservationData.car.carTypeId
								)
							) {
								reservationData.car = {
									...success.go.priceList.find(
										(elt) => elt.carTypeId == reservationData.car.carTypeId
									),
								};
								setReservationData({
									...reservationData,
									car: {
										...success.go.priceList.find(
											(elt) => elt.carTypeId == reservationData.car.carTypeId
										),
									},
								});
							}
						}

						if (success.calculatedTime) {
							result.hour = moment(
								success.calculatedTime,
								"DD/MM/YYYY HH:mm:ss"
							).tz("Europe/Paris");
						}

						if (success.start.terminals) {
							setTerminals(success.start.terminals);
						} else if (success.end.terminals) {
							setTerminals(success.end.terminals);
						}
						setReservationData({ ...reservationData, ...result });
					}
					setLoading(false);
				},
				(err) => {
					console.error("error", err);
					setLoading(false);
					if (err.message) {
						setError(err);
					}
				}
			);
		}
	};

	const getGeocode = (placeId) => {
		return new Promise((resolve, reject) => {
			geocoderService.current.geocode(
				{ placeId: placeId },
				(results, status) => {
					if (status === "OK") {
						resolve(results[0]);
					} else {
						reject(status);
					}
				}
			);
		});
	};

	const checkFlight = async () => {
		let formErrors = {};
		if (!reservationData.flightNum) {
			formErrors.flightNum = "Numéro de vol manquant";
		}
		if (!reservationData.date) {
			formErrors.date = "Date manquante";
		}
		setFormErrors(formErrors);

		if (Object.entries(formErrors).length != 0) {
			return;
		}

		let request = {
			flightNum: reservationData.flightNum,
			date: moment(reservationData.date)
				.tz("Europe/Paris")
				.format("DD/MM/YYYY"),
			productType: resultCheckAddress.bookingType,
			airportCode: resultCheckAddress.airportCode,
		};

		setLoading(true);

		api.post(
			"order/checkFlight",
			request,
			(success) => {
				console.log("success", success);
				if (success) {
					setFlightInfo(success);
					//setError(success.error);
					if (!success.error) {
						let result = {};
						if (success.flightTime) {
							result.flightTime = moment(success.flightTime, "HH:mm").tz(
								"Europe/Paris"
							);
						}

						if (success.originDestination) {
							result.origin = success.originDestination;
						}

						if (success.terminal) {
							let terminal = terminals.find(
								(elt) => elt.terminal == success.terminal
							);
							result.terminal = terminal.terminal;
							result.meetingPoint = terminal.meetingPoint;
						}

						callCheck.current = true;
						setReservationData({ ...reservationData, ...result });
					} else {
						setLoading(false);
					}
				} else {
					setLoading(false);
				}
			},
			(err) => {
				console.error(err);
				setLoading(false);
			}
		);
	};

	const reverseAddresses = () => {
		let lStartValue = { ...startValue };
		let lEndValue = { ...endValue };

		let lStartInput = startInputValue;
		let lEndInput = endInputValue;

		let lStart = { ...reservationData.start };
		let lEnd = { ...reservationData.end };

		setStartValue(lEndValue);
		setEndValue(lStartValue);

		setStartInputValue(lEndInput);
		setEndInputValue(lStartInput);

		setReservationData({ ...reservationData, start: lEnd, end: lStart });
		callCheck.current = true;
	};

	const isValid = () => {
		let valid = true;
		if (
			!reservationData.start ||
			!reservationData.end ||
			!reservationData.date ||
			!reservationData.hour ||
			!reservationData.car
		) {
			valid = false;
		}

		if (!resultCheckAddress.bookingType) {
			valid = false;
		} else if (resultCheckAddress.bookingType == 1) {
		} else {
			valid =
				reservationData.flightNum &&
				reservationData.flightTime &&
				!reservationData.originDestination &&
				reservationData.terminal &&
				reservationData.cabine !== "" &&
				reservationData.soute !== "";
		}

		return valid;
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const renderOption = (option) => {
		let matches = [];
		let parts = [];
		if (
			!option.history &&
			!option.mostUse &&
			option.option.structured_formatting
		) {
			matches = option.structured_formatting.main_text_matched_substrings;
			parts = parse(
				option.structured_formatting.main_text,
				matches.map((match) => [match.offset, match.offset + match.length])
			);
		}

		return (
			<Grid container>
				<Grid item>
					{option.history ? (
						<History className={classes.icon} />
					) : option.mostUse ? (
						option.type == "aeroport" ? (
							<LocalAirport className={classes.icon} />
						) : (
							<Train className={classes.icon} />
						)
					) : (
						<LocationOn className={classes.icon} />
					)}
				</Grid>
				<Grid item xs>
					{option.history || option.mostUse
						? option.address
						: parts.map((part, index) => (
								<span
									key={index}
									style={{ fontWeight: part.highlight ? 700 : 400 }}
								>
									{part.text}
								</span>
						  ))}

					<Typography variant="body2" color="textSecondary">
						{option.history || option.mostUse
							? ""
							: option.structured_formatting.secondary_text}
					</Typography>
				</Grid>
			</Grid>
		);
	};

	return (
		<React.Fragment>
			<Dialog
				open={openDialog}
				onClose={handleCloseDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" className={classes.dialogError}>
					Erreur
				</DialogTitle>
				<DialogContent className={classes.dialogError}>
					<DialogContentText
						id="alert-dialog-description"
						style={{ color: "#611a59" }}
					>
						{error.message}
					</DialogContentText>
				</DialogContent>
				<DialogActions className={classes.dialogError}>
					<Button
						onClick={handleCloseDialog}
						color="primary"
						style={{ color: "#d0152e" }}
					>
						Ok
					</Button>
				</DialogActions>
			</Dialog>

			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Grid container spacing={3} alignItems="center">
						<Grid item xs={10}>
							<Grid item xs={12} style={{ padding: "5px 0" }}>
								<Autocomplete
									id="start-address"
									style={{ width: "100%" }}
									getOptionLabel={(option) =>
										typeof option === "string" ? option : option.description
									}
									filterOptions={(x) => x}
									options={startOptions}
									autoComplete
									includeInputInList
									filterSelectedOptions
									value={startValue}
									onChange={(event, newValue) => {
										callCheck.current = true;
										setStartOptions(
											newValue ? [newValue, ...startOptions] : startOptions
										);

										if (newValue) {
											newValue.address = newValue.description;
										}
										setStartValue(newValue);

										setReservationData({
											...reservationData,
											start: {
												address: newValue ? newValue.description : "",
												description: newValue ? newValue.description : "",
												place_id: newValue ? newValue.place_id : null,
												geometry: newValue ? newValue.geometry : null,
											},
										});
									}}
									onInputChange={(event, newInputValue) => {
										setStartInputValue(newInputValue);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											label="Départ"
											fullWidth
										/>
									)}
									renderOption={renderOption}
								/>
							</Grid>
							{reservationData.intermediatePoints.map((elt, index) => {
								return (
									<Grid container style={{ padding: "0px 0", marginLeft: 5 }}>
										<Grid item xs={10} style={{ marginLeft: 5 }}>
											<Autocomplete
												id="start-address"
												style={{ width: "100%" }}
												getOptionLabel={(option) =>
													typeof option === "string"
														? option
														: option.description
												}
												filterOptions={(x) => x}
												options={elt.options}
												autoComplete
												includeInputInList
												filterSelectedOptions
												value={elt.value}
												onChange={(event, newValue) => {
													callCheck.current = true;
													let intermediatePoints = [
														...reservationData.intermediatePoints,
													];
													let iPoint = { ...intermediatePoints[index] };

													iPoint.options = newValue
														? [newValue, ...iPoint.options]
														: iPoint.options;
													iPoint.value = newValue;
													if (newValue) {
														iPoint.value.address = newValue.description;
													}
													intermediatePoints[index] = iPoint;
													iPoint.active = false;
													setReservationData({
														...reservationData,
														intermediatePoints,
													});
												}}
												onInputChange={(event, newInputValue) => {
													let intermediatePoints = [
														...reservationData.intermediatePoints,
													];
													let iPoint = { ...intermediatePoints[index] };
													iPoint.inputValue = newInputValue;
													iPoint.active = true;
													intermediatePoints[index] = iPoint;
													setReservationData({
														...reservationData,
														intermediatePoints,
													});

													// searchAddressIntermediate(iPoint, index);
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														variant="outlined"
														label="Point intermédiaire"
														fullWidth
													/>
												)}
												renderOption={renderOption}
											/>
										</Grid>

										<Grid item xs={1} style={{ marginLeft: 5 }}>
											<IconButton
												aria-label="delete"
												className={classes.margin}
												onClick={() => removeIntermediatePoint(index)}
											>
												<Delete fontSize="small" />
											</IconButton>
										</Grid>
									</Grid>
								);
							})}
							{reservationData.intermediatePoints.length < 5 && (
								<Grid item xs={12} style={{ padding: "5px 0", marginLeft: 5 }}>
									<Button
										variant="contained"
										size="small"
										startIcon={<Add />}
										onClick={() => addIntermediatePoint()}
									>
										Point intermédiaire
									</Button>
								</Grid>
							)}
							<Grid item xs={12} style={{ padding: "15px 0" }}>
								<Autocomplete
									id="end-address"
									style={{ width: "100%" }}
									getOptionLabel={(option) =>
										typeof option === "string" ? option : option.description
									}
									filterOptions={(x) => x}
									options={endOptions}
									autoComplete
									includeInputInList
									filterSelectedOptions
									value={endValue}
									onChange={(event, newValue) => {
										callCheck.current = true;
										setEndOptions(
											newValue ? [newValue, ...endOptions] : endOptions
										);

										if (newValue) {
											newValue.address = newValue.description;
										}
										setEndValue(newValue);

										setReservationData({
											...reservationData,
											end: {
												address: newValue ? newValue.description : "",
												description: newValue ? newValue.description : "",
												place_id: newValue ? newValue.place_id : null,
												geometry: newValue ? newValue.geometry : null,
											},
										});
									}}
									onInputChange={(event, newInputValue) => {
										setEndInputValue(newInputValue);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											label="Arrivée"
											fullWidth
										/>
									)}
									renderOption={renderOption}
								/>
							</Grid>
						</Grid>

						<Grid item xs={1}>
							<IconButton
								aria-label="reverse"
								onClick={() => reverseAddresses()}
							>
								<ImportExport fontSize="large" />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
				{!reservationData.idReturn && (
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Checkbox
									checked={reservationData.withReturn}
									onChange={handleChange}
									name="withReturn"
								/>
							}
							label="Avec retour"
						/>
					</Grid>
				)}
				{reservationData.town ? (
					<React.Fragment>
						<Grid item xs={12} container direction="row" spacing={5}>
							<Grid item xs={12}>
								<Typography variant="button">Ville :</Typography>

								<Typography variant="button">
									{" " + reservationData.town.toUpperCase()}
								</Typography>
							</Grid>
							<Grid item xs={resultCheckAddress.bookingType != 1 ? 4 : 6}>
								<DatePicker
									fullWidth
									error={formErrors.date}
									helperText={formErrors.date}
									ampm={false}
									label="Date"
									value={reservationData.date}
									onChange={handleDateChange}
									onError={console.log}
									disablePast
									format="DD/MM/YYYY"
									autoOk={true}
									onAccept={(date) => console.log("onAccept")}
								/>
							</Grid>
							{reservationData.bookingType != 1 && (
								<Grid item xs={6}>
									<Grid
										container
										direction="row"
										justifyContent="flex-start"
										alignItems="flex-start"
									>
										<TextField
											error={formErrors.flightNum}
											id="flightNum"
											name="flightNum"
											label="Numéro de vol"
											value={reservationData.flightNum}
											onChange={handleChange}
											helperText={formErrors.flightNum}
										/>
										<Button
											variant="outlined"
											size="small"
											onClick={() => checkFlight()}
											className={classes.button}
											style={{ height: 40, marginLeft: 5 }}
										>
											Vérifier le vol
										</Button>
										{flightInfo.error != "" && (
											<Typography
												variant="button"
												display="block"
												gutterBottom
												className={classes.error}
											>
												{flightInfo.error}
											</Typography>
										)}
									</Grid>
								</Grid>
							)}
							{reservationData.bookingType != 1 && (
								<Grid item xs={reservationData.bookingType != 1 ? 4 : 6}>
									<TimePicker
										fullWidth
										ampm={false}
										label={
											reservationData.bookingType == 3
												? "Heure d'atterrissage"
												: "Heure de décollage"
										}
										value={reservationData.flightTime || null}
										onChange={handleFlightTimeChange}
										onError={console.log}
										format="HH:mm"
										minutesStep={5}
										onAccept={(date) => console.log("hour")}
										autoOk={true}
									/>
								</Grid>
							)}
							<Grid item xs={resultCheckAddress.bookingType != 1 ? 4 : 6}>
								<TimePicker
									fullWidth
									ampm={false}
									label="Heure de prise en charge"
									value={reservationData.hour}
									onChange={handleHeureChange}
									onError={console.log}
									format="HH:mm"
									minutesStep={5}
									onAccept={(date) => console.log("hour")}
									autoOk={true}
								/>
								{/* <TimePicker
        clearable
        ampm={false}
        label="24 hours"
        value={selectedDate}
        onChange={handleDateChange}
      /> */}
							</Grid>
						</Grid>

						<Grid item xs={12} container direction="row" spacing={3}>
							<Grid item xs>
								<FormControl className={classes.formControl} fullWidth>
									<InputLabel id="passagers-label">Passagers</InputLabel>
									<Select
										labelId="passagers-label"
										id="nbPersons"
										name="nbPersons"
										value={reservationData.nbPersons}
										onChange={(event) => {
											if (
												resultCheckAddress &&
												resultCheckAddress.isCollective
											) {
												callCheck.current = true;
											}
											handleChange(event);
										}}
										startAdornment={
											<InputAdornment position="start">
												<Person />
											</InputAdornment>
										}
									>
										<MenuItem value="">
											<em></em>
										</MenuItem>
										<MenuItem value={1}>1</MenuItem>
										<MenuItem value={2}>2</MenuItem>
										<MenuItem value={3}>3</MenuItem>
										<MenuItem value={4}>4</MenuItem>
										<MenuItem value={5}>5</MenuItem>
										<MenuItem value={6}>6</MenuItem>
										<MenuItem value={7}>7</MenuItem>
										<MenuItem value={8}>8</MenuItem>
									</Select>
									{/* <FormHelperText>Some important helper text</FormHelperText> */}
								</FormControl>
							</Grid>
							<Grid item xs>
								<FormControl className={classes.formControl} fullWidth>
									<InputLabel id="soute-label">Soute</InputLabel>
									<Select
										labelId="soute-label"
										id="soute"
										name="soute"
										value={reservationData.soute}
										onChange={handleChange}
										startAdornment={
											<InputAdornment position="start">
												<SvgIcon style={{ height: 50 }}>
													<path
														xmlns="http://www.w3.org/2000/svg"
														d="M17,6h-2V3c0-0.55-0.45-1-1-1h-4C9.45,2,9,2.45,9,3v3H7C5.9,6,5,6.9,5,8v11c0,1.1,0.9,2,2,2c0,0.55,0.45,1,1,1 c0.55,0,1-0.45,1-1h6c0,0.55,0.45,1,1,1c0.55,0,1-0.45,1-1c1.1,0,2-0.9,2-2V8C19,6.9,18.1,6,17,6z M9.5,18H8V9h1.5V18z M12.75,18 h-1.5V9h1.5V18z M13.5,6h-3V3.5h3V6z M16,18h-1.5V9H16V18z"
													/>
												</SvgIcon>
											</InputAdornment>
										}
									>
										<MenuItem value="">
											<em></em>
										</MenuItem>
										<MenuItem value={0}>0</MenuItem>
										<MenuItem value={1}>1</MenuItem>
										<MenuItem value={2}>2</MenuItem>
										<MenuItem value={3}>3</MenuItem>
										<MenuItem value={4}>4</MenuItem>
										<MenuItem value={5}>5</MenuItem>
										<MenuItem value={6}>6</MenuItem>
										<MenuItem value={7}>7</MenuItem>
										<MenuItem value={8}>8</MenuItem>
									</Select>
									{/* <FormHelperText>Some important helper text</FormHelperText> */}
								</FormControl>
							</Grid>
							<Grid item xs>
								<FormControl className={classes.formControl} fullWidth>
									<InputLabel id="cabine-label">Cabine</InputLabel>
									<Select
										labelId="cabine-label"
										id="cabine"
										name="cabine"
										value={reservationData.cabine}
										onChange={handleChange}
										startAdornment={
											<InputAdornment position="start">
												<SvgIcon>
													<path
														xmlns="http://www.w3.org/2000/svg"
														d="M17,6h-2V3c0-0.55-0.45-1-1-1h-4C9.45,2,9,2.45,9,3v3H7C5.9,6,5,6.9,5,8v11c0,1.1,0.9,2,2,2c0,0.55,0.45,1,1,1 c0.55,0,1-0.45,1-1h6c0,0.55,0.45,1,1,1c0.55,0,1-0.45,1-1c1.1,0,2-0.9,2-2V8C19,6.9,18.1,6,17,6z M9.5,18H8V9h1.5V18z M12.75,18 h-1.5V9h1.5V18z M13.5,6h-3V3.5h3V6z M16,18h-1.5V9H16V18z"
													/>
												</SvgIcon>
											</InputAdornment>
										}
									>
										<MenuItem value="">
											<em></em>
										</MenuItem>
										<MenuItem value={0}>0</MenuItem>
										<MenuItem value={1}>1</MenuItem>
										<MenuItem value={2}>2</MenuItem>
										<MenuItem value={3}>3</MenuItem>
										<MenuItem value={4}>4</MenuItem>
										<MenuItem value={5}>5</MenuItem>
										<MenuItem value={6}>6</MenuItem>
										<MenuItem value={7}>7</MenuItem>
										<MenuItem value={8}>8</MenuItem>
									</Select>
									{/* <FormHelperText>Some important helper text</FormHelperText> */}
								</FormControl>
							</Grid>
						</Grid>

						{!!carModalList && (
							<Grid item xs={12}>
								<CarList
									handleSelectCar={(carTypeId) => {
										handleSelectCar(carTypeId);
										setCarModalOpen(false);
									}}
									carList={carModalList}
									// carModalOpen={carModalOpen}
									nbPersons={reservationData.nbPersons}
									soute={reservationData.soute}
									cabine={reservationData.cabine}
									// handleClose={() => setCarModalOpen(false)}
									selectedCar={reservationData.car}
								/>
							</Grid>
						)}

						{/* <Grid item xs={12} >
          <FormControlLabel
            control={<Checkbox checked={reservationData.hasCustomPrice} onChange={handleChange} name="hasCustomPrice" />}
            label="Prix personnalisé"
          />
          {reservationData.hasCustomPrice == true && ( 
          <TextField 
              id="customPrice" 
              name="customPrice" 
              label="Prix personnalisé"   
              value={reservationData.customPrice}
              onChange={handleChange} 
            />)}
        </Grid> */}

						{/* {reservationData.car && reservationData.car.carTypeName !== undefined && (
            <Grid item xs={12}>
                <InputLabel id="cartype-label">Véhicule</InputLabel>
            <List component="nav" aria-label="cars">
                <ListItem button>
                <ListItemIcon>
                  <DriveEta />
                </ListItemIcon>
                <ListItemText primary={`${reservationData.car.carTypeName} - ${reservationData.car.amount} €`}  
                      secondary={`Max pers. ${reservationData.car.nbMaxPers} - Max bag. ${reservationData.car.nbLuggage}`}/>
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => setCarModalOpen(true)}>
                      <Edit />
                    </IconButton>
                  </ListItemSecondaryAction>
              </ListItem>
              </List>
            </Grid>
          )} */}
						{reservationData.bookingType != 1 && (
							<React.Fragment>
								{/* <Grid item xs={12}>
                    <Grid container direction="row" xs={12} justifyContent="flex-start" alignItems="flex-end">
                      <TextField 
                        id="flightNum" 
                        name="flightNum" 
                        label="Numéro de vol"   
                        value={reservationData.flightNum}
                        onChange={handleChange} 
                      />
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => checkFlight()}
                        className={classes.button}
                        style={{height: 40, marginLeft: 5}}
                      >
                        Vérifier le vol
                      </Button>
                    </Grid>
                  </Grid> */}

								<Grid item xs={12}>
									<Grid
										container
										direction="row"
										justifyContent="flex-start"
										alignItems="flex-end"
										spacing={5}
									>
										<Grid item xs={4}>
											{/* <TextField 
                        id="terminal" 
                        name="terminal" 
                        label="Terminal"   
                        value={reservationData.terminal}
                        onChange={handleChange} 
                      /> */}

											<FormControl className={classes.formControl} fullWidth>
												<InputLabel id="terminal-label">Terminal</InputLabel>
												<Select
													labelId="terminal-label"
													id="terminal"
													name="terminal"
													value={reservationData.terminal}
												>
													<MenuItem value="">
														<em></em>
													</MenuItem>
													{terminals.map((elt, index) => (
														<MenuItem
															key={index}
															value={elt.terminal + ""}
															onClick={() => handleChangeTerminal(elt)}
														>
															{elt.terminal}
														</MenuItem>
													))}
												</Select>
												{/* <FormHelperText>Some important helper text</FormHelperText> */}
											</FormControl>
										</Grid>
										<Grid item xs={4}>
											<TextField
												id="origin"
												name="origin"
												label={
													reservationData.bookingType == 2
														? "Destination"
														: "Provenance"
												}
												value={reservationData.origin}
												InputLabelProps={{
													shrink: reservationData.origin != "" && true,
												}}
												onChange={handleChange}
												fullWidth
											/>
										</Grid>

										{reservationData.bookingType === 3 && (
											<Grid item xs={4}>
												<TextField
													id="meetingPoint"
													name="meetingPoint"
													label="Point de rendez-vous"
													fullWidth
													InputLabelProps={{
														shrink: reservationData.meetingPoint != "" && true,
													}}
													value={reservationData.meetingPoint}
													InputProps={{
														readOnly: true,
													}}
												/>
											</Grid>
										)}
									</Grid>
								</Grid>
							</React.Fragment>
						)}
						<Grid item xs={12} direction="row">
							<Grid container spacing={2}>
								<Grid item xs={3}>
									<FormControlLabel
										control={
											<Checkbox
												checked={reservationData.babySeat}
												onChange={handleChange}
												name="babySeat"
											/>
										}
										label="Siège bébé"
									/>
								</Grid>
								<Grid item xs={3}>
									<FormControlLabel
										control={
											<Checkbox
												checked={reservationData.boosterSeat}
												onChange={handleChange}
												name="boosterSeat"
											/>
										}
										label="Réhausseur"
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Checkbox
										checked={
											(customer && customer.with_autoroute == 1) ||
											reservationData.autoroute
										}
										disabled={customer && customer.with_autoroute == 1}
										onChange={handleChange}
										name="autoroute"
									/>
								}
								label="Autoroute"
							/>
						</Grid>
						<Grid item xs={12}>
							Le passager est-il différent du client ?
							<Button
								variant={
									reservationData.hasPassagers ? "contained" : "outlined"
								}
								size="small"
								className={classes.margin}
								name="hasPassagers"
								value={1}
								onClick={(e) =>
									handleChange({ target: { name: "hasPassagers", value: 1 } })
								}
								color="primary"
							>
								Oui
							</Button>
							<Button
								variant={
									!reservationData.hasPassagers ? "contained" : "outlined"
								}
								size="small"
								className={classes.margin}
								name="hasPassagers"
								value={0}
								onClick={(e) =>
									handleChange({ target: { name: "hasPassagers", value: 0 } })
								}
							>
								Non
							</Button>
						</Grid>
						{reservationData.hasPassagers ? (
							<React.Fragment>
								<Grid item xs={12} container>
									<Typography variant="subtitle1">Passagers 1</Typography>
									<Grid item xs={12} direction="row" container>
										<Grid item>
											<TextField
												id="nomPassager0"
												name="nom"
												value={reservationData.passagers[0].nom}
												onChange={(e) => handleChangePassager(e, 0)}
												label="Nom"
												style={{ width: "90%" }}
											/>
										</Grid>
										<Grid item>
											<TextField
												id="prenomPassager0"
												name="prenom"
												value={reservationData.passagers[0].prenom}
												onChange={(e) => handleChangePassager(e, 0)}
												label="Prénom"
												style={{ width: "90%" }}
											/>
										</Grid>
										<Grid item>
											<TextField
												required
												id="telephonePassager0"
												name="telephone"
												value={reservationData.passagers[0].telephone}
												onChange={(e) => handleChangePassager(e, 0)}
												label="Téléphone"
												style={{ width: "90%" }}
											/>
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12} container>
									<Typography variant="subtitle1">Passagers 2</Typography>
									<Grid item xs={12} direction="row" container>
										<Grid item>
											<TextField
												id="nomPassager1"
												name="nom"
												value={reservationData.passagers[1].nom}
												onChange={(e) => handleChangePassager(e, 1)}
												label="Nom"
												style={{ width: "90%" }}
											/>
										</Grid>
										<Grid item>
											<TextField
												id="prenomPassager1"
												name="prenom"
												value={reservationData.passagers[1].prenom}
												onChange={(e) => handleChangePassager(e, 1)}
												label="Prénom"
												style={{ width: "90%" }}
											/>
										</Grid>
										<Grid item>
											<TextField
												required
												id="telephonePassager1"
												name="telephone"
												value={reservationData.passagers[1].telephone}
												onChange={(e) => handleChangePassager(e, 1)}
												label="Téléphone"
												style={{ width: "90%" }}
											/>
										</Grid>
									</Grid>
								</Grid>
							</React.Fragment>
						) : null}

						<Grid item xs={12}>
							<TextField
								id="comment_customer"
								name="commentCustomer"
								label="Commentaire client"
								fullWidth
								multiline
								rowsMax={10}
								variant="outlined"
								value={reservationData.commentCustomer}
								onChange={handleChange}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								id="comment_driver"
								name="commentDriver"
								label="Commentaire chauffeur"
								fullWidth
								multiline
								rowsMax={10}
								variant="outlined"
								value={reservationData.commentDriver}
								onChange={handleChange}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								id="comment_facturation"
								name="commentFacturation"
								label="Commentaire facturation"
								fullWidth
								multiline
								rowsMax={10}
								variant="outlined"
								value={reservationData.commentFacturation}
								onChange={handleChange}
							/>
						</Grid>
					</React.Fragment>
				) : null}

				{/*<Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="shipping postal-code"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="shipping country"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
            label="Use this address for payment details"
          />
        </Grid> */}

				<Grid
					container
					direction="row"
					justifyContent="flex-end"
					alignItems="center"
				>
					<Button
						variant="outlined"
						color="secondary"
						onClick={() => props.cancelReservation()}
						className={classes.button}
					>
						Annuler
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={() => props.validateReservation(reservationData, true)}
						disabled={!isValid()}
						className={classes.button}
					>
						Valider
					</Button>
				</Grid>
			</Grid>

			<Backdrop className={classes.backdrop} open={loading} onClick={() => {}}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</React.Fragment>
	);
}
