import React, {useState, useEffect} from 'react';
import {Grid, FormControl, RadioGroup, Radio, FormControlLabel, CircularProgress, ListItemText, List, ListItem, TextField, Button,
  Dialog, DialogActions, DialogContent , DialogContentText,DialogTitle} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import api from '../utils/api';
import DialogError from './DialogError';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

  

function ListCustomer(props) {

  return (
    <List
    component="nav"
    aria-labelledby="nested-list-subheader"
  >
    {props.customerList.map((item, index) => {
      return (<ListItem button key={index}> <ListItemText primary={item.name} secondary={item.email + (item.billing_telephone ? " - " +item.billing_telephone : "")} 
            onClick={() => props.onClick(item)} key={item.entity_id}/></ListItem>)
    })}
     </List>
  )
}

export default function SearchCustomer(props) {
  const [customer, setCustomer] = useState(props.customer);
  const [customerList, setCustomerList] = useState([]);
  const [search, setSearch] = useState('');
  const [searching, setSearching] = useState(false);
  const [error, setError] = React.useState({});
  const [openDialog , setOpenDialog] = React.useState(false);

  const classes = useStyles();

  React.useEffect(() => {
    searchCustomer();
  }, [search])

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
    
  React.useEffect(() => {
    if(error.message){
      console.log('message change')
      setOpenDialog(true);
    }
  }, [JSON.toString(error)]);

  const searchCustomer = () => {

    if(search.length > 2){

        setSearching(true);
        api.get("clients", {search: search},
        success => {
          console.log("success", success);
          setCustomerList(success.customers);
          setSearching(false)
        },
        err => {
          setSearching(false)
          
          if(err.message){
            console.log('error messsage' , err.message)
            setError(err);
          }else{
            setError({message: err.toString()});
          }
          
          setOpenDialog(true);
          
        } 
      );
    }else{
      setCustomerList([]);
      setSearching(false)
    }
  }
  
  const pickCustomer = (item) => {

    if(item.billing_street){
      let street = item.billing_street.split('\n');
      item.street1 = street[0];
      if(street[1]){
        item.street2 = street[1];
      }
    }

    // setCustomer(item);
    // setSearch(item.name);
    setCustomerList([]);
    setSearch('');

    props.onSelectCustomer(item);
    // setCustomer({});
  }
  
  

  return (
    <React.Fragment>
    <Dialog 
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogError}>Erreur</DialogTitle>
      <DialogContent className={classes.dialogError}>
        <DialogContentText id="alert-dialog-description" style={{color: "#611a59"}}>
          {error.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogError}>
        <Button onClick={handleCloseDialog} color="primary"style={{color: "#d0152e"}}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
      <Grid item xs={12} style={{position: 'relative', marginBottom: 20}}>
      <TextField
        id="search"
        name="search"
        label="Rechercher un client"
        value={search}
        fullWidth
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          readOnly: customer.entity_id != null,
        }}
      />
      <CloseIcon style={{position: 'absolute', right: 10, top: 20, display: search != '' && !searching ? 'inline' : 'none'}} 
        onClick={() => {
            setCustomerList([]);
            setSearch('');
      }}/>
      {searching && (<CircularProgress style={{position: 'absolute', right: 10, top: 20, width : 20, height: 20}}  />)}

      {/* <CloseIcon style={{position: 'absolute', right: 10, top: 20, display: customer.entity_id ? 'inline' : 'none'}} 
          onClick={() => {
            reinitCustomer();
            setSearch('');
          }}/> */}
        {customerList.length > 0 && (<Grid item xs={12} style={{margin: '0 5px', position:'absolute', right: 5, left: 5, background: '#FFF', zIndex: 9999, width: '100%', border:'1px solid #DDD'}}>
        <ListCustomer customerList={customerList} onClick={(item) => {
          pickCustomer(item);
        }}
        /> 
      </Grid>)}
    </Grid>
  </React.Fragment>
)

}