import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Grid } from "@material-ui/core";
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
	listItem: {
		padding: theme.spacing(1, 0),
	},
	total: {
		fontWeight: 700,
	},
	title: {
		marginTop: theme.spacing(2),
	},
}));

export default function Review({ order, onNewOrder }) {
	const classes = useStyles();

	return (
		<React.Fragment>
			<Grid
				container
				justifyContent="center"
				direction="column"
				alignItems="center"
			>
				<Grid>
					<Typography variant="h6" gutterBottom>
						Commande # {order.increment_id}
						<Button
							onClick={() =>
								window.open(
									"http://admin.pickmecab.fr/index.php/admin/admin/sales_order/view/order_id/" +
										order.entity_id,
									"_blank"
								)
							}
							color="primary"
							style={{ color: "#d0152e" }}
						>
							Voir la commande
						</Button>
					</Typography>
				</Grid>
				<Grid>
					<Button
						onClick={onNewOrder}
						variant="contained"
						color="primary"
						style={{ color: "#FFF" }}
					>
						Nouvelle commande
					</Button>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}
