import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {CssBaseline, Paper, Grid, Card, CardActions, CardContent, Button,
  Typography, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Backdrop, CircularProgress, 
  Dialog, DialogActions, DialogContent , DialogContentText,DialogTitle, Checkbox, IconButton, TextField  } from '@material-ui/core';
import CustomerForm from './CustomerForm';
import ReservationForm from './ReservationForm';
import SearchCustomer from './components/SearchCustomer'
import LastReservationsModal from './components/LastReservationsModal'
// import PaymentForm from './PaymentForm';
import Review from './Review';
import ReservationsList from './components/ReservationsList';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CachedIcon from '@material-ui/icons/Cached';
import ListAltIcon from '@material-ui/icons/ListAlt';
// import Alert from '@material-ui/lab/Alert';

import moment from 'moment';
import api from './utils/api';
import DialogError from './components/DialogError';

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://material-ui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    background: "#f6f6f6",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    width: 800,
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      // marginTop: theme.spacing(6),
      // marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    border: "1px solid #DDD"
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  }, 
  bottomButton: {
    marginRight: 5
  }, 
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  totalLine: {
    textAlign: "right",
    fontSize: 15,
    fontWeight: "bold",
    color: "#000"
  },
  total: {
    marginBottom: 5,
    fontSize: 15,
    fontWeight: "bold",
    color: "#000"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  alert: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  dialogError: {
    backgroundColor: "#eacbc8",
    color: "#d0152e"
  }
}));

const steps = ['Client', 'Infos. Réservation', 'Paiement'];

export default function Checkout(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(localStorage.getItem('activeStep') || "checkout");
  const [customer, setCustomer] = React.useState(JSON.parse(localStorage.getItem('customer')) || {
    firstname: '',
    lastname: '',
    email: '',
    billing_telephone: '',
    address1: '',
    address2: '',
    billing_postcode: '',
    billing_city: '',
    billing_country_id: 'FR',
    street1: '',
    street2: '',
    billing_company: '',
    customer_type : '1'
  });
  const [reservations, setReservations] = React.useState(JSON.parse(localStorage.getItem('reservations')) || []);
  const [currentReservation, setCurrentReservation] = React.useState(localStorage.getItem('currentReservation') || 0);
  const [order, setOrder] = React.useState(JSON.parse(localStorage.getItem('order'))  || {sendMail: 1, totals : {}, items: []});
  const [error, setError] = React.useState({});
  const [openDialog , setOpenDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  // const  [lastReservations,setLastReservations] =  React.useState([]);
  const  [lastAddresses,setLastAddresses] =  React.useState([]);
  const  [openLastModal,setOpenLastModal] =  React.useState(false);

  const handleBack = () => {
    setActiveStep('checkout');
  };

  const onSelectCustomer = async (nCustomer) => {
    if(nCustomer.entity_id){
      nCustomer.lastReservations = await getLastReservations(nCustomer.entity_id);
      console.log("onSelectCustomer", nCustomer);
    }
    setCustomer({...nCustomer})
    handleBack();
    localStorage.setItem('customer', JSON.stringify(nCustomer));
  };


  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if(token){
      localStorage.setItem('token', JSON.stringify(token));
    }

    let lastUpdate = localStorage.getItem('lastUpdate');
    if(!lastUpdate ||(lastUpdate && moment.duration(moment().diff(lastUpdate)).as('hours') > 1)) {
      reinit();
    }

  });

  const onRemoveCustomer = (nCustomer) => {
    setCustomer({...nCustomer})
    localStorage.setItem('customer', JSON.stringify({...nCustomer}));
  }

  React.useEffect(() => {
    localStorage.setItem('order', JSON.stringify(order));
  }, [order]);

  React.useEffect(() => {
    localStorage.setItem('activeStep', activeStep);
    localStorage.setItem('currentReservation', currentReservation != null ? currentReservation : '');
    localStorage.setItem('reservations', JSON.stringify(reservations));
    localStorage.setItem('customer', JSON.stringify(customer));
    localStorage.setItem('lastUpdate', moment().format());
    
  }, [activeStep, currentReservation, reservations, customer]);

  React.useEffect(() => {
    if(error.message){
      setOpenDialog(true);
    }
  }, [error]);

  const handleChange = ({target : {name, value, type, checked}}) => {
    
    const updatePath = name.split(".");

    // if the input is a checkbox then use callback function to update
    // the toggle state based on previous state
    if (type === 'checkbox') {
      setOrder((prevOrderData) => ({
        ...prevOrderData,
        [name]: checked
      }))

      return
    }

    if (updatePath.length === 1) {
      const [key] = updatePath;

      setOrder(prevOrderData => ({
          ...prevOrderData,
          [key]: value
      }));
    }
    
  }

  const createFromPrevious = (item) => {
    addReservation({start : {...item.start}, end: {...item.end}, nbPersons: 1});
    setOpenLastModal(false)
  }

  const addReservation = (fromReservation, idReturn) => {

    reservations.push({
      start: fromReservation ? (idReturn ? {...fromReservation.end} : {...fromReservation.start}) : null,
      end: fromReservation ? (idReturn ? {...fromReservation.start} : {...fromReservation.end}) : null,
      nbPersons: fromReservation ? fromReservation.nbPersons : 1,
      date: null, hour: null,
      cabine: fromReservation ? fromReservation.cabine : 1, 
      soute: fromReservation ? fromReservation.soute : 1,
      terminal: '',
      passagers : fromReservation && fromReservation.passagers ? [...fromReservation.passagers] : [{nom:'', prenom:'', telephone:''}, {nom:'', prenom:'', telephone:''}],
      autoroute: 0,
      car: {},
      intermediatePoints: [],
      idReturn: idReturn || null,
    });

    setReservations(reservations);
    setCurrentReservation(reservations.length - 1);
    setActiveStep('reservation');
  }

  const editReservation = (index) => {
    setCurrentReservation(index);
    setActiveStep('reservation');
  };

  const getHistoryAddresses = () => {
    let history = [];
    if(customer && customer.lastReservations){
      history = customer.lastReservations.reduce((acc, current) => {
        console.log(acc);
        if(!acc.find(elt => elt.address == current.start.address)){
          acc.push({...current.start, history: true});
        }

        if(!acc || !acc.find(elt => elt.address == current.end.address)){
          acc.push({...current.end, history: true});
        }
        return acc;
        }, []);
    }
    return history;
  }

  const validateReservation = (reservationData, goBack) => {

    reservationData.hourStr =  moment(reservationData.hour).format('HH:mm');
    if(reservationData.flightTime){
      reservationData.flightTimeStr =  moment(reservationData.flightTime).format('HH:mm');
    }
    reservationData.dateStr = moment(reservationData.date).format('DD/MM/YYYY');

    let nReservation = {...reservationData};
    let current = currentReservation;
    reservations[currentReservation] = nReservation;
    if(goBack){
      nReservation.validated = true;
      let redirectReturn = false;
      if(nReservation.withReturn && !reservations.find(elt => elt.idReturn === currentReservation)){
        redirectReturn = currentReservation;
        // addReservation(nReservation, currentReservation);
      }
      setCurrentReservation(null);
      handleBack();
      checkout(true, reservations, redirectReturn);
      // }
    }
    setReservations(reservations);
    localStorage.setItem('reservations', JSON.stringify(reservations));

    
  };

  const reinit = () => {
    setOrder({sendMail: 1, items: []});
    reinitCustomer();
    setReservations([]);

    getMostUsesAddresses();
    
    handleBack();
  }

  const getMostUsesAddresses = async () => {

    await api.get("getAddresses", {},
    success => {
      console.log("success", success);
      if(success.error){
        setError(success.error);
        return [];
      }

      setError({});
      setLoading(false);
      localStorage.setItem('mostUsesAddresses', JSON.stringify([...success.addresses]));

    },
    err =>{
      console.error(err)
      
      setLoading(false);
    } 
  );
  }

  const reinitCustomer = () => {
    let nCustomer = {
      firstname: '',
      lastname: '',
      email: '',
      billing_telephone: '',
      address1: '',
      address2: '',
      billing_postcode: '',
      billing_city: '',
      billing_country_id: 'FR',
      street1: '',
      street2: '',
      billing_company: '',
      customer_type : '1',
      entity_id: null
    };
    setCustomer(nCustomer);
  }


  const cancelReservation = (forceCancel) => {
    if((currentReservation && !reservations[currentReservation].validated) || forceCancel !== undefined){
      if(forceCancel !== undefined){
        console.log(currentReservation, forceCancel)
        reservations.splice(forceCancel, 1);
      }else{
        reservations.splice(currentReservation, 1);
      }
      setReservations(reservations);
    }
    setCurrentReservation(null);
    handleBack();
  };

  const getLastReservations = async (entity_id) => {
    setLoading(true);
    return await api.get("client/lastReservations", {customerId: entity_id},
    success => {
      console.log("success", success);
      if(success.error){
        setError(success.error);
        return [];
      }
        setError({});
        // setLastReservations([...success.lastReservations]);

        // customer.lastReservations = [...success.lastReservations];
        // setCustomer({...customer});
        
        setLoading(false);
        return [...success.lastReservations]
    },
    err =>{
      console.error(err)
      
      setLoading(false);
    } 
  );

  }

  const getStepContent = (step) => {
    switch (step) {
      case "customer":
        return <CustomerForm onSelectCustomer={(customer) => onSelectCustomer(customer)} customer={customer} handleBack={handleBack} />;
      case "reservation":
        return <ReservationForm customer={customer} validateReservation={(reservationData, goBack) => validateReservation(reservationData, goBack)} reservation={reservations[currentReservation]} 
                cancelReservation={() => cancelReservation() } handleBack={handleBack} historyAddresses={getHistoryAddresses()}/>;
      // case 2:
      //   return <PaymentForm />;
      case "review":
        return <Review order={order.orderCreated} onNewOrder={reinit} />;
      default:
        throw new Error('Unknown step');
    }
  }

  const checkout = async (getTotals = false, reservationItems = [], redirectReturn = false) => {

      if(reservationItems.length == 0){
        reservationItems = reservations;
      }
      order.items = reservationItems.filter(elt => elt.validated === true);
      order.customer = customer;
    
      let orderToSend = {...order};
      delete orderToSend.totals;
      setLoading(true);
      orderToSend.getTotals =  getTotals ? 1 : 0;

      api.post("order/create", orderToSend,
        success => {
          console.log("success", success);
          if(success.error){
            setError(success.error);
          }else{

            setError({});
            if(getTotals){
              setOrder({...order, totals: {...success}})
            }else{
              setOrder({...order, orderCreated: {...success}});
              setActiveStep('review');
            }

            if(redirectReturn !== false){
              addReservation(reservations[redirectReturn], redirectReturn);
            }

          }
          setLoading(false);
        },
        err =>{
          console.error(err)
          
          setLoading(false);
        } 
      );
    // }
  }

  const isValid = () => {
    return reservations.length && customer.firstname && order.orderType && order.provenience && order.paymentMethod;
  }
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      {/* <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Company name
          </Typography>
        </Toolbar>
      </AppBar> */}
      <main className={classes.layout}>
        <Paper elevation={0} className={classes.paper}>
        {/* {error.message && (
        <Alert variant="filled" severity="error" className={classes.alert}>
          {error.message}
        </Alert>
        )} */}

        <DialogError error={error} />
      {/* <Dialog 
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogError}>Erreur</DialogTitle>
        <DialogContent className={classes.dialogError}>
          <DialogContentText id="alert-dialog-description" style={{color: "#611a59"}}>
            {error.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogError}>
          <Button onClick={handleCloseDialog} color="primary"style={{color: "#d0152e"}}>
            Ok
          </Button>
        </DialogActions>
      </Dialog> */}
          {/* <Typography component="h1" variant="h4" align="center">
            Checkout
          </Typography> */}
          {/* <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper> */}
          <React.Fragment>
            {activeStep == "checkout" ? (
              <React.Fragment>
                 {!customer.firstname ? (
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={6}>
                    <SearchCustomer customer={customer} onSelectCustomer={(customer) => onSelectCustomer(customer)} onRemoveCustomer={(customer) => onRemoveCustomer(customer)}/>
                  </Grid>
                  <Grid item xs={6} style={{textAlign: 'center'}}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      disabled={customer.entity_id && !customer.new}
                      startIcon={<AddIcon />}
                      onClick={() => setActiveStep('customer')}
                      style={{marginTop: 0}}
                    >
                    Ajouter un client
                    </Button>
                  </Grid>
                </Grid>) : null}
                {customer.firstname ? (
                  <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {customer.firstname + ' ' + customer.lastname}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                    {customer.billing_telephone + ' - ' + customer.email}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                    {(customer.billing_postcode ?? '' ) + ' ' + (customer.billing_city ?? '') + ' - ' + (customer.billing_country_id??'')}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {/* <Button size="small" variant="outlined" startIcon={<EditIcon />} >Modifier</Button> */}
                    {/* <Button size="small" variant="outlined" startIcon={<EditIcon />} onClick={() => reinitCustomer()}>Changer de client</Button> */}
                    <IconButton color="default" aria-label="Modifier les informations du client" component="span" onClick={() => setActiveStep('customer')}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="default" aria-label="Changer de client" component="span"  onClick={() => reinitCustomer()}>
                      <CachedIcon />
                    </IconButton>
                    {customer.lastReservations && customer.lastReservations.length > 0 && (
                    <IconButton color="default" aria-label="Afficher les derniers trajets du clients" component="span" onClick={() => setOpenLastModal(true)}>
                      <ListAltIcon />
                    </IconButton>)}
                  </CardActions>
                </Card>
                  ) : null}
                <ReservationsList reservationsList={reservations} onClick={() => addReservation()} editReservation={(index) => editReservation(index)}
                        cancelReservation={(index) => cancelReservation(index)} />
                {/* <Typography variant="h5" gutterBottom>
                  Thank you for your order.
                </Typography>
                <Typography variant="subtitle1">
                  Your order number is #2001539. We have emailed your order confirmation, and will
                  send you an update when your order has shipped.
                </Typography> */}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
              </React.Fragment>
            )}
          </React.Fragment>
          {activeStep == "checkout" && order.items && order.items.length > 0 && (
          <React.Fragment>
            <Grid container style={{marginTop: 30}}>
              <Grid item xs={4}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Type</FormLabel>
                  <RadioGroup aria-label="orderType" name="orderType" value={order.orderType || null} onChange={handleChange}>
                    <FormControlLabel value="pickmecab" control={<Radio />} label="Pickmecab" />
                    <FormControlLabel value="routair" control={<Radio />} label="Routair" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Provenance</FormLabel>
                  <RadioGroup aria-label="provenience" name="provenience" value={order.provenience || null} onChange={handleChange}>
                    <FormControlLabel value="telephone" control={<Radio />} label="Téléphone" />
                    {/* <FormControlLabel value="siteweb" control={<Radio />} label="Routair" /> */}
                    <FormControlLabel value="email" control={<Radio />} label="Email" />
                    <FormControlLabel value="ag_blanc" control={<Radio />} label="Agence" />
                    <FormControlLabel value="autre" control={<Radio />} label="Autre" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Mode paiement</FormLabel>
                  <RadioGroup aria-label="paymentMethod" name="paymentMethod" value={order.paymentMethod || null} onChange={handleChange}>
                    <FormControlLabel value="paybox_system" control={<Radio />} label="Carte bancaire" />
                    <FormControlLabel value="invoicepayment" control={<Radio />} label="A faire facturer" />
                    <FormControlLabel value="paymentdriver" control={<Radio />} label="A faire payer" />
                    <FormControlLabel value="validationclient" control={<Radio />} label="En attente validation client" />
                    <FormControlLabel value="validationpickmecab" control={<Radio />} label="En attente validation Pickmecab" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container style={{marginTop: 30}}>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth
                  id="emailsTransfert" 
                  name="emailsTransfert" 
                  label="Emails en copie"   
                  value={order.emailsTransfert}
                  onChange={handleChange} 
                  helperText="séparés par virgule"
                />
              </Grid>
            </Grid>
            {reservations.length && order.totals && parseFloat(order.totals.price_ht) > 0 ?  (
              <React.Fragment>
                <Grid container style={{marginTop: 30, padding: "15px", border: "1px solid #DDD", background: "#EEE"}}>
                <Grid item xs={12}>
                  <Typography style={{color: "#000", fontSize: 15, fontWeight: "bold", marginBottom: 15}} gutterBottom>Total de la commande</Typography>
                </Grid>

                {/* <Grid xs={6} className={classes.totalLine}><Typography className={classes.total} color="textSecondary">Sous-total HT</Typography></Grid>
                <Grid xs={6} className={classes.totalLine}>{order.totals.price_ht ? parseFloat(order.totals.price_ht).toFixed(2) : ''} €</Grid>
                <Grid xs={6} className={classes.totalLine}><Typography className={classes.total} color="textSecondary">Sous-total TTC</Typography></Grid>
                <Grid xs={6} className={classes.totalLine}>{order.totals.price_ttc ? parseFloat(order.totals.price_ht).toFixed(2) : ''} €</Grid> */}
                <Grid item xs={6} className={classes.totalLine}><Typography className={classes.total} color="textSecondary">Total HT</Typography></Grid>
                <Grid item xs={6} className={classes.totalLine}>{order.totals.price_ht ? parseFloat(order.totals.price_ht).toFixed(2) : ''} €</Grid>
                <Grid item xs={6} className={classes.totalLine}><Typography className={classes.total} color="textSecondary">TVA</Typography></Grid>
                <Grid item xs={6} className={classes.totalLine}>{order.totals.price_ht ? parseFloat(order.totals.tax.value).toFixed(2) : ''} €</Grid>
                <Grid item xs={6} className={classes.totalLine}><Typography className={classes.total} color="textSecondary">Total TTC</Typography></Grid>
                <Grid item xs={6} className={classes.totalLine}>{order.totals.price_ht ? parseFloat(order.totals.grand_total).toFixed(2) : ''} €</Grid>

              <Grid container style={{justifyContent: "flex-end"}}>
                <FormControlLabel
                  control={<Checkbox checked={order.sendMail} onChange={handleChange} name="sendMail" />}
                  label="Envoyer mail de confirmation"
                />
              </Grid>
              </Grid>
             </React.Fragment>
            ): null}

            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {}}
                className={classes.bottomButton, classes.button}
              >
                Annuler
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => checkout()}
                disabled={!isValid()}
                className={classes.bottomButton, classes.button}
              >
                Valider
              </Button>
            </Grid>

          </React.Fragment>)}
          {openLastModal && (
          <LastReservationsModal open={openLastModal} onClose={() => setOpenLastModal(false)} list={customer.lastReservations} onClick={(item) => createFromPrevious(item)}/>)}
        </Paper>
        {/* <Copyright /> */}
      </main>
      <Backdrop className={classes.backdrop} open={loading} onClick={() => {}}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}
